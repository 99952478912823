import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled, { css } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Redirect } from '@reach/router'

// helpers
import { up, addResponsivity } from '../lib/styles'

// components
import Col, { BasicPageContentCol } from '../components/Col'
import Title from '../components/Title'
import Gap from '../components/Gap'
import Text, { BasicWhiteText } from '../components/Text'
import SEO from '../components/SEO'

import { Page, PageContent, PageWithBgImage } from '../components/Page'

import quizConf from '../data/quiz'
import CloseQuizButton from '../components/CloseQuizButton'

import { extractNodesFromData } from '../lib/helpers'
import ShareWidget from '../components/ShareWidget'
import SuggestionsFooter from '../components/SuggestionsFooter'
import Link from '../components/Link'

const htmlToReactParser = new HtmlToReactParser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const getProcessingInstruction = () => [
  {
    // replace p tag with custom component
    shouldProcessNode(node) {
      return node.name === 'locallink'
    },
    processNode(node, children, index) {
      // console.log('node, children, index', node, children, index)
      return <Link to={node.attribs.to}>{children}</Link>
    },
  },

  {
    // Anything else
    shouldProcessNode(node) {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

const { results } = quizConf

export default function ResultTemplate({
  data,
  path,
  pageContext,
  location: { pathname },
  ...rest
}) {
  // console.log('data', data)

  const [isQuizDone, setIsQuizDone] = useLocalStorageState('quiz-done', false)

  const {
    allResultsImages,
    site: {
      siteMetadata: { siteUrl, twitterUsername },
    },
  } = extractNodesFromData(data)
  const { resultKey } = pageContext
  const result = results[resultKey]

  const imageFile = allResultsImages.find(
    img => img.relativePath === `images/quiz-results/${result.image}`,
  )

  const image = getImage(imageFile)

  // console.log('path', path, rest)

  const title = `Moje firma je jako ${result.familyName}. Jaká je vaše firma?`

  const reactElement = htmlToReactParser.parseWithInstructions(
    result.text,
    () => true,
    getProcessingInstruction(),
  )

  useEffect(() => {
    if (!isQuizDone) {
      navigate(Link.QUIZ)
    }
  }, [isQuizDone])

  return (
    <>
      <SEO
        title={`${result.familyName} — Kvíz`}
        socialTitle={title}
        description="Poznáváte se občas v některých seriálových postavách? Anebo v nich vidíte ostatní členy svého rodinného klanu? Máte v rodině nějakého J. R. Ewinga, primáře Sovu nebo snad Homera Simpsona? Odpovězte na pár otázek a zjistíte, jaký seriál váš rodinný podnik připomíná."
        image={`/quiz-results-images/${result.image}`}
        pathname={pathname}
      />

      <PageWithBgImage blurred>
        <PageContent>
          <Col alignItems="flex-end">
            <CloseQuizButton />
            <Gap mobileGap="16px" gap="0" />
          </Col>
          <BasicPageContentCol alignItems="center">
            <Text
              color="white"
              font="public"
              size="32px"
              mobileSize="24px"
              lineHeight="130%"
              mobileLineHeight="140%"
              weight="800"
              textAlign="center"
            >
              Vaše rodinná firma je jako
            </Text>
            <Gap gap="8px" />
            <Title
              as="h1"
              weight="900"
              size="64px"
              mobileSize="40px"
              mobileLineHeight="48px"
              lineHeight="72px"
              color="gold"
              alignSelf="center"
              textAlign="center"
            >
              {result.familyName}
            </Title>
            <Gap gap="40px" mobileGap="24px" />
            <GatsbyImage image={image} alt={result.familyName} css={css``} />
            <Gap gap="40px" mobileGap="32px" />
            <BasicWhiteText
              width="100%"
              mobileFontSize="17px"
              mobileLineHeight="140%"
            >
              {reactElement}
            </BasicWhiteText>
            <Gap gap="40px" mobileGap="32px" />
            <ShareWidget path={pathname} title={title} />
            <Gap gap="54px" mobileGap="40px" />
          </BasicPageContentCol>
        </PageContent>
        <SuggestionsFooter withoutColor hideQuiz noPadding />
      </PageWithBgImage>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allResultsImages: allFile(
      filter: { relativePath: { regex: "/^images/quiz-results/" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 720
              height: 405
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl: url
        twitterUsername
      }
    }
  }
`
